import CookiePanel from './CookiePanel'
import UiStarRating from './UiStarRating'
import LanguageControl from './LanguageControl'
import UiDropdown from './UiDropdown'
import UiShare from './UiShare'
import UiShareToggler from './UiShareToggler'
import UiCollapse from './UiCollapse'
import VideoWrapper from './VideoWrapper'
import UiLightbox from './UiLightbox'
import UiCountdown from './UiCountdown'
import ServiceTable from './ServiceTable'
import ScrollTopButton from './ScrollTopButton'
import TableScroller from './TableScroller'
import ModalHandler from './ModalHandler'
import UiSearch from './UiSearch'
import UiClickOutside from './UiClickOutside'
import UiPreloader from './UiPreloader'
import UiAccordion from './UiAccordion'
import UiTabs from './UiTabs'
import UiImageZoomer from './UiImageZoomer'
import Switch from './Switch'
import CitySelect from './CitySelect'
import UiCopy from './UiCopy'

export default function install(Vue) {
  Vue.component('cookie-panel', CookiePanel)
  Vue.component('language-control', LanguageControl)
  Vue.component('video-wrapper', VideoWrapper)
  Vue.component('service-table', ServiceTable)
  Vue.component('scroll-top-button', ScrollTopButton)
  Vue.component('table-scroller', TableScroller)
  Vue.component('modal-handler', ModalHandler)
  Vue.component('ui-collapse', UiCollapse)
  Vue.component('ui-countdown', UiCountdown)
  Vue.component('ui-click-outside', UiClickOutside)
  Vue.component('ui-dropdown', UiDropdown)
  Vue.component('ui-star-rating', UiStarRating)
  Vue.component('ui-share', UiShare)
  Vue.component('ui-share-toggler', UiShareToggler)
  Vue.component('ui-search', UiSearch)
  Vue.component('ui-lightbox', UiLightbox)
  Vue.component('ui-preloader', UiPreloader)
  Vue.component('ui-accordion', UiAccordion)
  Vue.component('ui-tabs', UiTabs)
  Vue.component('ui-image-zoomer', UiImageZoomer)
  Vue.component('ui-switch', Switch)
  Vue.component('ui-city-select', CitySelect)
  Vue.component('ui-copy', UiCopy)
}
