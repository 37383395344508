<template lang="pug">
  include ../mixins
  +b.compare
    +e.heading-wrapper
      +e.heading-container
        +e.heading(ref="heading")
          +b.g-row.--appearance_spaced.--align_center.--justify_between.w-100
            +b.g-cell
              +b.g-row.--appearance_spaced
                +b.g-cell
                  +b.A.i-link.--color_main.--size_2xs.--size_sm-sm(:href="categoryLink")
                    +e.icon.--offset_right.--color_relief-5
                      icon-plus
                    +e.SPAN.text.is-hidden-lg {{ _("Додати ще") }}
                    +e.SPAN.text.is-visible-lg {{ _("Додати ще один продукт") }}
                +b.g-cell
                  modal-handler(
                    url="Modals/CompareView"
                    modal-class="vm-modal--size_xs"
                    #default="{ open }"
                  )
                    +b.i-link.--color_main.--size_2xs.--size_sm-sm(@click.prevent="open")
                      +e.icon.--offset_right.--color_fill-relief-5
                        icon-compare
                      +e.SPAN.text.is-hidden-lg {{ _("Списки") }}
                      +e.SPAN.text.is-visible-lg {{ _("Списки порівняння") }}
                +b.g-cell
                  +b.i-link.--color_main.--size_2xs.--size_sm-sm(@click.prevent="clearCompareCategory")
                    +e.icon.--offset_right.--color_relief-5
                      icon-trash
                    +e.SPAN.text.is-hidden-lg {{ _("Очистити") }}
                    +e.SPAN.text.is-visible-lg {{ _("Очистити порівняння") }}
            portal-target(name="scrollSlider")
    template(v-if="compareIsAvailable")
      +e.switch-mobile
        +b.g-row
          +b.g-cell
            +b.P.ds-caption.--size_2xs(
              :class="!isAllAttrs ? 'ds-caption--color_main' : 'ds-caption--color_grey'"
            ) {{ _("Усі характеристики") }}
          +b.g-cell
            ui-switch(
              v-model="isAllAttrs"
              @input="getData"
            )
          +b.g-cell
            +b.P.ds-caption.--size_2xs(
              :class="isAllAttrs ? 'ds-caption--color_main' : 'ds-caption--color_grey'"
            ) {{ _("Тільки відміності") }}
      +e.wrapper(ref="wrapper")
        +e.aside
          +e.switch-desktop
            +e.switch(ref="switchWrapper")
              +b.g-row.--appearance_column.--align_center.--appearance_spaced
                +b.g-cell
                  +b.P.ds-caption.--size_sm(
                    :class="!isAllAttrs ? 'ds-caption--color_main' : 'ds-caption--color_grey'"
                  ) {{ _("Усі характеристики") }}
                +b.g-cell
                  ui-switch(
                    v-model="isAllAttrs"
                    @input="getData"
                  )
                +b.g-cell
                  +b.P.ds-caption.--size_sm(
                    :class="isAllAttrs ? 'ds-caption--color_main' : 'ds-caption--color_grey'"
                  ) {{ _("Тільки відміності") }}
          +e.attributes
            +b.u-table
              +e.row.--variant_1.js-nav-rows(
                v-for="(attr, index) in compareInfo.attrs"
                :data-index="index"
                :style="{ 'height': `${rowHeight[index]}px` }"
              )
                +e.cell.--border_none.--width_full
                  +b.P.ds-caption.--size_md.--weight_semi-bold {{ attr.title }}
        +e.content
          table-scroller(
            ref="scroller"
          )
            +e.product-wrapper(ref="scrollerContentElement")
              +e.item(v-for="item in compareInfo.items")
                +e.product
                  product-card-compare(
                    ref="productPreview"
                    :item="item.cardDescription"
                    @remove="removeItem(item.cardDescription)"
                  )
                +e.attributes
                  +b.u-table
                    +e.row.--variant_1.js-nav-rows(
                      v-for="(attr, index) in compareInfo.attrs"
                      :data-index="index"
                      :style="{ 'height': `${rowHeight[index]}px` }"
                    )
                      +e.cell.--border_none.--width_full
                        +b.P.ds-caption.--size_xxs.--weight_semi-bold.is-hidden-xl {{ attr.title }}
                        +b.P.ds-caption.--size_xs.--size_md-xl.--size_md-xl {{ item[attr.value] }}
    template(v-else-if="compareInfo.items.length")
      +e.empty-container
        +e.notification
          +b.P.ds.-caption.--size_md.--weight_semi-bold {{ _("Недостатньо товарів для порівняння. Додайте ще хоча б один товар.") }}
        +e.item
          product-card-compare(
            :item="compareInfo.items[0].cardDescription"
            @remove="removeItem(compareInfo.items[0].cardDescription)"
          )
</template>

<script>
import { openConfirmModal } from '@utils/submit'
import {
  getCompareCardsResource,
  turnComparisonResource,
  compareCategoryRemoveResource,
} from '@services/comparison.service.js'

export default {
  props: {
    categoryLink: String,
    categorySlug: String,
    categoryId: String,
  },

  data() {
    return {
      productPlaceholder: window.productPlaceholder,
      compareInfo: {
        items: [],
      },
      rowHeight: {},
      isAllAttrs: false,
    }
  },

  async mounted() {
    await this.getData()

    window.addEventListener('scroll', this.scrollHandler)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  },

  computed: {
    compareIsAvailable() {
      return this.compareInfo.items.length > 1
    },
  },

  methods: {
    async getData() {
      const params = {
        category: this.categorySlug,
        isAllAttributes: !this.isAllAttrs,
      }

      await getCompareCardsResource.execute({}, params).then(async res => {
        this.compareInfo = res.data

        if (!this.compareInfo.items.length) {
          window.location = window.indexPageUrl
        }

        if (!this.compareIsAvailable) return

        this.rowHeight = {}

        await this.$nextTick()

        this.updateProductRowHeight()

        if (!this.$refs.scroller) return

        this.$refs.scroller.checkScrollAvailability()
      }).catch(() => {
        window.location = window.indexPageUrl
      })
    },

    removeItem(product) {
      const formdata = { idProduct: product.id }

      turnComparisonResource.execute({}, formdata).then(() => {
        this.getData()
      })
    },

    async clearCompareCategory() {
      const title = this._('Ви впевнені, що хочете очистити порівняння?')

      await openConfirmModal({ title }, this)

      const params = { idCategory: this.categoryId }

      compareCategoryRemoveResource.execute({}, params).then(() => {
        window.location = window.indexPageUrl
      })
    },

    updateProductRowHeight() {
      const rows = [...document.querySelectorAll('.js-nav-rows')]

      this.rowHeight = rows.reduce((acc, el) => {
        const rowIndex = el.getAttribute('data-index')

        el.style.height = 'auto'

        if (acc[rowIndex]) {
          if (el.clientHeight > acc[rowIndex]) {
            acc[rowIndex] = el.clientHeight
          }
        } else {
          acc[rowIndex] = el.clientHeight
        }

        return acc
      }, {})
    },

    scrollHandler() {
      const IS_DESKTOP = 1200 <= window.innerWidth

      const { wrapper, switchWrapper } = this.$refs
      /* eslint-disable radix */
      const { top } = wrapper.getBoundingClientRect()
      const absTop = Math.abs(top)
      const productPreviews = this.$refs.productPreview.map(item => item.$el)

      const headerHeight = document.querySelector('.header').clientHeight
      const compareHeading = this.$refs.heading

      const PREVIEW_IMAGE_HEIGHT = IS_DESKTOP ? -35 : 30
      const CARD_PADDING = IS_DESKTOP ? 30 : 15

      if (top + PREVIEW_IMAGE_HEIGHT + CARD_PADDING < headerHeight) {
        const baseOffset = (absTop + headerHeight) - 1
        const offset = top > 0 ? headerHeight - top : baseOffset
        const totalOffset = IS_DESKTOP ? offset + 89 : offset

        productPreviews.forEach(item => {
          item.style.top = `${totalOffset}px`
          item.classList.add('is-sticky')
        })

        switchWrapper.style.top = `${totalOffset}px`
        switchWrapper.classList.add('is-sticky')

        compareHeading.classList.add('is-sticky')
      } else {
        productPreviews.forEach(item => {
          item.style.top = 'auto'
          item.classList.remove('is-sticky')
        })

        switchWrapper.style.top = 'auto'
        switchWrapper.classList.remove('is-sticky')

        compareHeading.classList.remove('is-sticky')
      }
    },
  },
}
</script>
